import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
// import RichTextComponent from '../General/RichTextComponent'
import SectionWrapper from '../General/SectionWrapper'
// import { Link } from 'gatsby'
// import { serviceIconList } from '../../utitlities/IconRenderMap'
// import Highlights from './Highlights'
// import TechnologiesUsed from './TechnologiesUsed'
import Portfolio from '../Home/Portfolio'
// import PricingPack from './PricingPack'
import PricingSections from './PricingSections'
import TitleComponent from '../General/TitleComponent'
import { portfolioServices } from '../../utitlities/Configurations'
import VideoComponent from '../General/VideoComponent'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    linkContainer: {
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
        borderRadius: "6px",
        padding: '20px',
        transition: 'all 0.3s ease-in-out',
        "& svg": {
            fill: theme.palette.primary.main
        },
        "& #link": {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            boxShadow: "0px 2px 27px 0px rgb(0 0 0 / 9%)",
            "& #link": {
                color: theme.palette.secondary.main
            },
            "& svg": {
                fill: theme.palette.secondary.main
            },
        }
    },
    sectionContainer: {
        padding: "0px 0px 2rem",
        [theme.breakpoints.up("sm")]: {
            padding: "0px 0px 2rem"
        },
        [theme.breakpoints.up("md")]: {
            padding: "0px 0px 3rem"
        }
    },
    listItem: {
        marginBottom: '1rem',
        "&:last-child": {
            marginBottom: '0px'
        }
    }
}))

// function AdditionContentSection({
//     highlights = [], technologiesUsed = [], portfolioList = {}, pricingHeader = '', pricingHeaderHighlights = [],
//     pricingPacks = []
// }) {
//     const classes = useStyles()
//     return <Grid container spacing={3}>
//         {/* {
//             technologiesUsed.length !== 0 &&
//             <Grid item xs={12}>
//                 <div className={classes.sectionContainer}>
//                     <TechnologiesUsed list={technologiesUsed} listXs={6} listSm={2} />
//                 </div>
//             </Grid>
//         }
//         {
//             highlights.length !== 0 &&
//             <Grid item xs={12}>
//                 <div className={classes.sectionContainer}>
//                     <Highlights list={highlights} listXs={12} listSm={6} listMd={4} />
//                 </div>
//             </Grid>
//         } */}
//         {
//             portfolioList.links.length !== 0 &&
//             <Grid item xs={12}>
//                 <div className={classes.sectionContainer}>
//                     <Portfolio
//                         clipList={true}
//                         PortfolioList={portfolioList}
//                         listSm={6} listMd={4}
//                         sectionStyles={{
//                             padding: '0px'
//                         }}
//                     />
//                 </div>
//             </Grid>
//         }
//         {
//             pricingPacks.length !== 0 &&
//             <Grid item xs={12}>
//                 <PricingPack
//                     packs={pricingPacks}
//                     title={pricingHeader}
//                     secondaryTitles={pricingHeaderHighlights}
//                     sectionStyles={{ padding: '0px' }}
//                 />
//             </Grid>
//         }
//     </Grid>
// }

export default function ServiceDetails({ pricingSections = [],  highlights = {}, serviceId = '', videoUrl = '' }) {
    const classes = useStyles()
    // console.log('navas', pricingSections)
    return <SectionWrapper id='service-details'>
        <Grid container spacing={3}>
            {
                
                     <Grid key={highlights.id} item xs={12}>
                        <div className={classes.sectionContainer}>
                            <Grid container spacing={3} style={{ width: '100%', margin: '0px' }}>
                                { 
                                    highlights.points.length 
                                    ?
                                    <Grid item xs={12}>
                                        <TitleComponent
                                            title={highlights.title}
                                            secondaryTitles={highlights.titleHighlights}
                                            titleAlign='center'
                                            isFullWidth={true}
                                        />
                                    </Grid>
                                    : ''
                                }

                                <Grid item container justifyContent='center' xs={12}>
                                    <Grid item xs={12} sm={10} md={8}>
                                        <Grid container spacing={2}>
                                            {
                                                highlights.points.map((item, index) => {
                                                    return <Grid key={index} item xs={12}>
                                                        <Typography component={'p'} variant='h6' align='center' >
                                                            {item} &nbsp;
                                                            <span style={{ verticalAlign: 'middle', display: 'inline-block', color: '#15aa56' }}>
                                                                <CheckCircleIcon fontSize='large' />
                                                            </span>
                                                        </Typography>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                

            }
            {
                videoUrl &&
                <Grid container alignItems='center' justifyContent='center' spacing={4}>
                    <Grid xs={12} sm={12} md={10} alignItems='center' justifyContent='center'>
                        <div style={{
                            paddingBottom: '3rem'
                        }}>
                            <VideoComponent url={videoUrl} title='video' />
                        </div>
                    </Grid>
                </Grid>
            }
            {
                portfolioServices.includes(serviceId) &&
                <Grid item xs={12}>
                    <div className={classes.sectionContainer}>
                        <Portfolio
                            clipList={true}
                            // PortfolioList={portfolioList}
                            serviceId={serviceId}
                            // expandBrandingCards={false}
                            listSm={6} listMd={4}
                            sortKey={'serviceOrder'}
                            sectionStyles={{
                                padding: '0px'
                            }}
                        />
                    </div>
                </Grid>
            }
            {
                pricingSections.length !== 0 &&
                <Grid item xs={12}>

                    <PricingSections
                        sections={pricingSections.map(item => ({
                            packs: item.pricingPacks,
                            title: item.detailPagePricingTitle,
                            secondaryTitles: item.detailPagePricingTitleHighlights,
                            styles: { padding: '0px' }
                        }))}
                    />

                    {/* <PricingSections
                        sections={[{
                            packs: pricingSections.pricingPacks,
                            title: pricingSections.detailPagePricingTitle,
                            secondaryTitles: pricingSections.detailPagePricingTitleHighlights,
                            styles: { padding: '0px' }
                        }]}
                    /> */}
                    {/* <PricingPack
                        packs={pricingPacks}
                        title={pricingHeader}
                        secondaryTitles={pricingHeaderHighlights}
                        sectionStyles={{ padding: '0px' }}
                    /> */}
                </Grid>
            }
        </Grid>

    </SectionWrapper >
}


// <Grid item xs={12} >
//     <Grid container spacing={3}>

//         {/* <Grid item xs={12}>
//                         <RichTextComponent text={description} />
//                     </Grid> */}
//         {/* {
//                         (technologiesUsed.length !== 0 || highlights.length !== 0 || portfolioList.links.length !== 0 || pricingPacks.length !== 0) &&
//                         <Grid item xs={12}>
//                             <Hidden mdUp implementation='css'>
//                                 <AdditionContentSection
//                                     highlights={highlights}
//                                     technologiesUsed={technologiesUsed}
//                                     portfolioList={portfolioList}
//                                     pricingPacks={pricingPacks}
//                                     pricingHeader={pricingHeader}
//                                     pricingHeaderHighlights={pricingHeaderHighlights}
//                                 />
//                             </Hidden>
//                         </Grid>

//                     } */}
//     </Grid>
// </Grid>
// {/* <Grid item xs={12} sm={12} md={remainingServices.length === 0 ? 12 : 4}>
//                 <Grid container spacing={3}>
//                     {
//                         remainingServices.map(service => {
//                             return <Grid item xs={12} sm={6} md={12}>
//                                 <Link className={classes.link} to={`${!!linkPrefix ? `/${linkPrefix}/` : ''}${service.slug}${!!linkPrefix ? `/` : ''}`}>
//                                     <div className={classes.linkContainer}>
//                                         <Grid container spacing={2} alignItems='center'>
//                                             <Grid item xs={3} md={2}>
//                                                 {serviceIconList[service.id]}
//                                             </Grid>
//                                             <Grid item xs={9} md={10}>
//                                                 <Typography id='link' varaint='body1' color='primary'>{service.title}</Typography>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Link>
//                             </Grid>
//                         })
//                     }
//                 </Grid>
//             </Grid> */}
// {/* {
//                 (technologiesUsed.length !== 0 || pricingPacks.length !== 0 || highlights.length !== 0 || portfolioList.links.length !== 0) &&

//                 <Grid item xs={12}>
//                     <Hidden smDown implementation='css'>
//                         <AdditionContentSection
//                             highlights={highlights}
//                             technologiesUsed={technologiesUsed}
//                             portfolioList={portfolioList}
//                             pricingPacks={pricingPacks}
//                         />
//                     </Hidden>
//                 </Grid>

//             } */}