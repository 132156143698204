import React, {useState, useEffect} from 'react'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import Testimonials from '../components/Home/Testimonials' 
import ContactForm from '../components/Contact/Form'
import ServiceDetailsComponent from "../components/Services/ServiceDetails"
import { mainLeadFormDivId, mainLeadFormId, getStartedFormId, getStartedFormDivId } from '../utitlities/Configurations'
import { fetchData } from '../utitlities/helperFunctions'

const ServicePage = (props) => {   
  const [ContactData,SetData] = useState()     
  useEffect(() => { 
    fetchData(`services`)
    .then(x => SetData(x) )         
}, []); 
let Path = props.path
let Sp = Path.split('/')
Path = Sp[Sp.length-1] 
const contactPageData = ContactData ? ContactData.links : []
let FetchService = false
const propName = props.params.name ? props.params.name : Path 
let ServiceDetails = {photo_url:'', id:'', pricingSections: [], Seo:{}, highlights:{points:[]}} 
contactPageData.forEach(function (arrayItem) {
  if (arrayItem.id === propName) {      
    ServiceDetails = arrayItem
    FetchService = true
  }
});


const details = ServiceDetails
const serviceBanner = {url:ServiceDetails.photo_url}
const pricingSections = !!details.pricingSections ? details.pricingSections : []
const ServiceTestimonials = !!details.testimonials ? details.testimonials : [] 
const TestimonialVideo = !!details.videoLink ? [{ id:1, video_link : details.videoLink}] : []
return (
  
                    <Layout pageSeo={details.Seo}>            
                        <Banner
                            bannerId={`service-${details.id}-banner`}
                            heading={details.title}
                            subHeading={details.excerpt}
                            imageComponent={serviceBanner}
                            imageUrl={true}
                            showFormInsteadButton={true}
                            formId={getStartedFormId}
                            formDivId={getStartedFormDivId}
                        />
                        <ServiceDetailsComponent
                            pricingSections={pricingSections}
                            serviceId={details.id}
                            highlights={details.highlights}
                            videoUrl={details.videoLink}
                             />
                             {/*<div>{
                              FetchService ? 
                                <Testimonials FromService={true} SerTestimonials={ServiceTestimonials} videoData={TestimonialVideo} />
                              : ""
                              }
                            </div>*/}
                        
                        <ContactForm title="Get Free Quote Now" secondaryTitles={['Free', 'Quote']} showTitle={true} titleComponent="h2" titleVariant="h2"
                            formId={mainLeadFormId}
                            smallTitle="Contact Us"
                            formDivId={`${mainLeadFormDivId}-footer`}
                        // description={"We would love to hear from you about your upcoming projects, Please mention the type of your event, event date, location, timing and how many people attending the event, etc."}
                        />
                    </Layout>
                )
                
}
     
  export default ServicePage